define("incus-vastaanota/pods/tools/settings/controller", ["exports", "ember", "incus-vastaanota/utils/email", "incus-vastaanota/config/environment"], function (exports, _ember, _incusVastaanotaUtilsEmail, _incusVastaanotaConfigEnvironment) {
    function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

    exports["default"] = _ember["default"].Controller.extend({
        apixclient: _ember["default"].inject.service(),
        i18n: _ember["default"].inject.service(),
        actions: {
            updateCustomer: function updateCustomer() {
                var i18n = this.get('i18n');
                var model = this.get("model");
                var apixclient = this.get("apixclient");
                var userData = this.get("session").get("user")[0];
                var addressData = this.get("session").get("user")[1];
                var Name = model.get("name");
                var BusinessID = model.get("businessID");
                var Street1 = model.get("address");
                var City = model.get("city");
                var PostCode = model.get("zip");
                var ContactPerson = model.get("contactPerson");
                var PhoneNumber = model.get("phone");
                var Www = model.get("www");
                var Email = model.get("email");
                var Fax = model.get("fax");
                var eInvoiceAddress = model.get("eInvoiceAddress");
                var DefaultPaymentDays = Number(model.get("selectedPaymentCondition"));
                var CountryCode = model.get("selectedCountry");
                var PenaltyInterest = Number(model.get("penaltyInterest"));
                var bankAccounts = model.get("bankAccounts");
                bankAccounts = bankAccounts.filter(function (acc) {
                    return acc.Value !== '' && acc.valid;
                });
                var Role = "ISSUER";
                var Vatid = "FI" + BusinessID.replace("-", "");
                var CustomerNumber = -1;
                var Logo = model.get("imageURL") || null;
                var Data = [{ Type: "EMAIL", Value: Email }, { Type: "PHONE", Value: PhoneNumber }, { Type: "WWW", Value: Www }, { Type: "FAX", Value: Fax }, { Type: "EINVOICEADDRESS", Value: eInvoiceAddress }].concat(_toConsumableArray(bankAccounts));
                var data = { ContactPerson: ContactPerson, DefaultPaymentDays: DefaultPaymentDays, AnyParty: { Name: Name, BusinessID: BusinessID, Street1: Street1, PenaltyInterest: PenaltyInterest, City: City, PostCode: PostCode, Role: Role, Vatid: Vatid, CustomerNumber: CustomerNumber, CountryCode: CountryCode }, Data: Data, Logo: Logo };
                apixclient.updateInvoicingCustomerData(userData, data).then(function (response) {
                    if (response.success) {
                        console.log("Updated customerdata");
                        //this.get("notify").info(i18n.t("settings.userInfo.updatedUser").toString())                  
                    }
                })["catch"](function (err) {
                    console.log(err);
                });
            },
            addBankAccountRow: function addBankAccountRow() {
                var model = this.get("model");
                if (model.get("queryDone")) {
                    var id = model.get("rowId");
                    var arr = model.get("bankAccounts");
                    arr.pushObject({ Type: "IBAN", Value: "", id: id });
                    this.send("updateCustomer");
                    model.set("rowId", id + 1);
                }
            },
            deleteBankAccountRow: function deleteBankAccountRow(id) {
                var model = this.get("model");
                if (model.get("queryDone")) {
                    var arr = model.get("bankAccounts");
                    var acc = arr.find(function (i) {
                        return i.id === id;
                    });
                    arr.removeObject(acc);
                    this.send("updateCustomer");
                }
            },
            selectCountry: function selectCountry(evt) {
                this.get("model").set("selectedCountry", evt.target.value);
                this.send("updateCustomer");
            },
            selectAddressCountry: function selectAddressCountry(evt) {
                var addressData = this.model.get("addresses");
                var addressToUpdate = addressData.find(function (addr) {
                    return addr.AddressType === evt.target.name;
                });
                _ember["default"].set(addressToUpdate, "Country", evt.target.value);
            },
            sendLogo: function sendLogo() {
                var _this = this;

                var i18n = this.get('i18n');
                var model = this.get("model");
                var apixclient = this.get("apixclient");
                var userData = this.get("session").get("user")[0];
                var data = model.get("imageURL");
                apixclient.sendInvoicingCustomerLogo(userData, data).then(function (response) {
                    if (response.success) {
                        _this.get("notify").info(i18n.t("settings.userInfo.logoSuccess").toString());
                    }
                })["catch"](function (err) {
                    console.log(err);
                });
            },

            deleteLogo: function deleteLogo() {
                var _this2 = this;

                var i18n = this.get("i18n");
                var model = this.get("model");
                var apixclient = this.get("apixclient");
                var userData = this.get("session").get("user")[0];
                var data = model.get("imageURL");
                apixclient.deleteInvoicingCustomerLogo(userData).then(function (response) {
                    if (response.success) {
                        model.set("imageURL", '');
                        _this2.get("notify").info(i18n.t("settings.userInfo.logoDeleted").toString());
                        document.getElementById("invoice-logo").value = "";
                    }
                })["catch"](function (err) {
                    console.log(err);
                });
            },
            selectAddress: function selectAddress(evt) {
                this.get("model").set("selectedAddress", evt.target.value);
            },

            changeUserData: function changeUserData() {
                var _this3 = this;

                var i18n = this.get("i18n");
                var userData = this.get("session").get("user")[0];

                var newData = {
                    Name: this.get("model.settingsName"),
                    AdditionalName: this.get("model.settingsAdditionalName"),
                    WWW: this.get("model.settingsWww"),
                    PhoneNumber: this.get("model.settingsPhone"),
                    ContactPerson: this.get("model.settingsContactPerson")
                };
                var addressData = this.get("model.addresses");

                var requestData = this._requestDataString(userData, newData, addressData);
                this.get("apixclient").changeUserData(userData, requestData).then(function (response) {
                    if (response.callStatus.success === "OK") {
                        (function () {
                            var user = _this3.get("session.user");
                            Object.keys(newData).forEach(function (key) {
                                if (key === "WWW") {
                                    _ember["default"].set(user.objectAt(0), "Www", newData[key]);
                                } else if (key === "PhoneNumber") {
                                    _ember["default"].set(user.objectAt(0), "Phonenumber", newData[key]);
                                } else {
                                    _ember["default"].set(user.objectAt(0), key, newData[key]);
                                }
                            });
                            addressData.forEach(function (address) {
                                var isEmpty = true;
                                Object.keys(address).forEach(function (key) {
                                    if (address[key] && key !== "AddressType") {
                                        isEmpty = false;
                                    }
                                });
                                if (!isEmpty) {
                                    (function () {
                                        var ind = user.findIndex(function (data) {
                                            return data.hasOwnProperty("AddressType") && data.AddressType === address.AddressType;
                                        });
                                        if (ind > 0) {
                                            Object.keys(address).forEach(function (key) {
                                                _ember["default"].set(user.objectAt(ind), key, address[key]);
                                            });
                                        } else {
                                            user.pushObject(address);
                                        }
                                    })();
                                }
                            });
                            var session = _this3.get("session.user");
                            sessionStorage.setItem("session", JSON.stringify(session));
                            _this3.get("notify").info(i18n.t("settings.userInfo.updatedUser").toString());
                        })();
                    } else {
                        _this3.get("notify").info(i18n.t("settings.userInfo.updateUserFailed").toString());
                    }
                })["catch"](function (error) {
                    console.log(error);
                    _this3.get("notify").info(i18n.t("settings.userInfo.updateUserFailed").toString());
                });
            },

            changePassword: function changePassword() {
                var _this4 = this;

                var userData = this.get("session").get("user")[0];
                var i18n = this.get("i18n");
                var oldPass = this.get("model.oldPass");
                var repeatPass = this.get("model.repeatPass");
                var newPass = this.get("model.newPass");
                if (newPass !== repeatPass) {
                    this.get("notify").info(i18n.t("settings.userInfo.updatePasswordsNomatch").toString());
                    return;
                }
                var callback = function callback(feedback) {
                    switch (feedback) {
                        case "success":
                            _this4.get("notify").info(i18n.t("settings.userInfo.updatedPassword").toString());
                            break;
                        case "fail":
                            _this4.get("notify").info(i18n.t("settings.userInfo.updatePasswordFailed").toString());
                            break;
                        case "wrongPass":
                            _this4.get("notify").info(i18n.t("settings.userInfo.updateWrongPass").toString());
                    }
                };
                this.get("apixclient").changePassword(userData, oldPass, newPass, callback);
            }
        },

        _requestDataString: function _requestDataString(userData, data, addresses) {
            var _this5 = this;

            var doc = window.document.implementation.createDocument(null, "Request", null);
            var request = doc.firstChild;
            request.setAttribute("version", "1.0");
            var content = doc.createElement("Content");
            var group = doc.createElement("Group");

            Object.keys(data).forEach(function (key) {
                group.appendChild(_this5._valueElement(doc, key, data[key]));
            });
            group.appendChild(this._valueElement(doc, "y-tunnus", userData.Ytunnus));
            group.appendChild(this._valueElement(doc, "Email", userData.Email));
            group.appendChild(this._valueElement(doc, "Language", userData.LanguageCoded));
            content.appendChild(group);

            addresses.forEach(function (address) {
                var isEmpty = true;
                Object.keys(address).forEach(function (key) {
                    if (address[key] && key !== "AddressType") {
                        isEmpty = false;
                    }
                });
                if (!isEmpty) {
                    group = doc.createElement("Group");
                    Object.keys(address).forEach(function (key) {
                        group.appendChild(_this5._valueElement(doc, key, address[key]));
                    });
                    content.appendChild(group);
                }
            });
            request.appendChild(content);
            var xs = new XMLSerializer();
            var header = "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"yes\" ?>\n";
            //header = "";
            console.log(header + xs.serializeToString(doc));
            return header + xs.serializeToString(doc);
        },
        _valueElement: function _valueElement(doc, type, text) {
            var el = doc.createElement("Value");
            el.setAttribute("type", type);
            el.textContent = text;
            return el;
        }
    });
});