define("incus-vastaanota/services/apixclient", ["exports", "ember", "incus-vastaanota/utils/email", "incus-vastaanota/utils/demo", "incus-vastaanota/config/environment"], function (exports, _ember, _incusVastaanotaUtilsEmail, _incusVastaanotaUtilsDemo, _incusVastaanotaConfigEnvironment) {
  exports["default"] = _ember["default"].Service.extend({
    session: _ember["default"].inject.service(),
    spinner: _ember["default"].inject.service(),

    /* Each _apiCall returns the data as an array consisting of at least status
     * object and possible grouped elements. The first element contains the
     * OK/ERR status.
     */

    _apiCall: function _apiCall(method, path, params) {
      var _this = this;

      _ember["default"].Logger.log(method + " " + path);

      if (_incusVastaanotaUtilsDemo["default"].check_call(method, path, params)) {
        return _incusVastaanotaUtilsDemo["default"].handle_call(this, method, path, params);
      }
      this._eventLogEntry(_incusVastaanotaConfigEnvironment["default"].apiroot + path, method);
      this.get("spinner").show();
      return new _ember["default"].RSVP.Promise(function (resolve, reject) {
        return _ember["default"].$.ajax({
          url: _incusVastaanotaConfigEnvironment["default"].apiRoot + path,
          method: method,
          data: params,
          dataType: "xml",
          success: function success(data) {
            _this.get("spinner").hide();
            if (data) {
              data = _this._transformToObject(data);
              if (data.callStatus.success === "OK") {
                _ember["default"].Logger.log("OK: " + method + " " + path);
                resolve(data);
              } else {
                _ember["default"].Logger.log("ERROR: " + method + " " + path);
                resolve(data);
                //reject(new Error("Code: " + data.callStatus.success + ": " + data.callStatus.freeText[1].$));
              }
            } else {
                _ember["default"].Logger.log("Request success, but no data received.");
                resolve(null);
              }
          },
          error: function error(data) {
            _this.get("spinner").hide();
            _ember["default"].run(null, reject, data);
          }
        });
      });
    },

    _transformToObject: function _transformToObject(data) {
      var result = {};
      var resultSet = [];
      var apiCallStatus = data.getElementsByTagName("Status").item(0).textContent;
      var foo = data.getElementsByTagName("Status");
      var apiCallStatusCode = data.getElementsByTagName("StatusCode").item(0).textContent;
      var statusObj = {
        success: apiCallStatus,
        statusCode: apiCallStatusCode
      };
      if (apiCallStatus !== "OK") {
        if (data.getElementsByTagName("FreeText") && data.getElementsByTagName("FreeText")[0]) {
          var collection = data.getElementsByTagName("FreeText");
          statusObj.freeText = "";
          for (var i = 0; i < collection.length; i++) {
            statusObj.freeText = statusObj.freeText + collection.item(i).textContent + ",\r\n";
          }
        } else {
          statusObj.freeText = "Unknown error";
        }
      }
      result.callStatus = statusObj;
      var group = data.getElementsByTagName("Group");
      for (var i = 0; i < group.length; i++) {
        var obj = {};
        var value = group[i].getElementsByTagName("Value");
        for (var j = 0; j < value.length; j++) {
          var key = value[j].getAttribute("type");
          var content = value[j].textContent;
          obj[key] = content;
        }
        resultSet.push(obj);
      }
      result.data = resultSet;
      //Ember.Logger.log(result);

      console.log(["_transformToObject", data, result]);

      return result;
    },

    /* _signQuery for apix rest api
     *
     */
    _signQuery: function _signQuery(params, keysToSign, signOnly) {
      params = _ember["default"].$.extend({}, params);

      var values = keysToSign.map(function (key) {
        return params[key];
      }).filter(function (value) {
        return value !== null;
      });
      var digest = values.join("+");
      //Ember.Logger.log("Calculating digest: " + digest);

      params.d = CryptoJS.SHA256(digest).toString();
      return params;
    },

    /* Creates a SHA256 digest from args and value. Value is not part of the
     * params, but present in the digest.
     */
    _digestStringWithValue: function _digestStringWithValue(args, value) {
      //Ember.Logger.log(args.join('+'));
      if (value) {
        args.push(CryptoJS.SHA256(value));
      }
      //Ember.Logger.log(args.join('+'));
      var digest = "SHA-256:" + CryptoJS.SHA256(args.join("+"));
      //Ember.Logger.log(digest);
      return digest;
    },

    _formattedTime: function _formattedTime() {
      return moment().utc().format("YYYYMMDDHHmmss");
    },

    _eventLogEntry: function _eventLogEntry(url, method) {

      var session = localStorage.getItem("session");
      if (session && !url.includes('saldo') && !url.includes('session') && Array.isArray(session)) {
        session = JSON.parse(session);
        var tid = session[0].TransferID;
        var tkey = session[0].TransferKey;
        var user = session[0].Email;
        var t = this._formattedTime();
        var d = this._digestStringWithValue([btoa(url), user, method, tid, t, tkey]);
        var xhr = new XMLHttpRequest();
        xhr.open("GET", _incusVastaanotaConfigEnvironment["default"].apiRoot + "/seteventlog?TraID=" + tid + "&url=" + btoa(url) + "&method=" + method + "&user=" + user + "&d=" + d + "&t=" + t);
        xhr.send();
      }
    },

    // TODO is this still needed? Unify with _apiCall
    // TODO clean this up.
    _terminalApiCall: function _terminalApiCall(method, path, params, contentType) {
      var _this2 = this;

      this._eventLogEntry(path, method);
      _ember["default"].Logger.log(method + " " + path);
      //Ember.Logger.log(params);
      var url = path;

      if (_incusVastaanotaUtilsDemo["default"].check_call(method, path, params)) {
        var data = _incusVastaanotaUtilsDemo["default"].handle_call(this, method, path, params);
        return data;
      }

      var ajaxParameters = {
        url: url,
        method: method,
        data: params
        //dataType: 'xml', //jquery works strangely with this combined with contentType, intelliguess seems to be working ok
      };

      // Override some parameters for PUT requests.
      if (method === "PUT" || method === "DELETE") {
        ajaxParameters.processData = false;
        ajaxParameters.url = path;
        if (!contentType) {
          contentType = "";
        }
        ajaxParameters.contentType = contentType;
      }

      _ember["default"].Logger.log(ajaxParameters);
      _ember["default"].Logger.log(ajaxParameters.url);

      this.get("spinner").show();

      return new _ember["default"].RSVP.Promise(function (resolve, reject) {
        return _ember["default"].$.ajax(_ember["default"].$.extend(ajaxParameters, {
          success: function success(data) {
            _this2.get("spinner").hide();
            if (data) {
              data = _this2._transformToObject(data);
              if (data.callStatus.success === "OK") {
                _ember["default"].Logger.log("OK: " + method + " " + path);
                resolve(data);
              } else {
                _ember["default"].Logger.log("ERROR: " + method + " " + path);
                resolve(data);
                //reject(new Error("Code: " + data.callStatus.success + ": " + data.callStatus.freeText[1].$));
              }
            } else {
                _ember["default"].Logger.log("Request success, but no data received.");
                resolve(null);
              }
          },
          error: function error(data, status, errorThrown) {
            _this2.get("spinner").hide();
            _ember["default"].Logger.log(data);
            _ember["default"].Logger.log("failure");
            _ember["default"].Logger.log(["failure", status, errorThrown]);
            _ember["default"].run(null, reject, data);
          }
        }));
      });
    },

    _JSONApiCall: function _JSONApiCall(method, path, params, contentType, token) {
      var _this3 = this;

      this._eventLogEntry(path, method);

      _ember["default"].Logger.log(method + " " + path);
      //Ember.Logger.log(params);
      var url = path;

      if (_incusVastaanotaUtilsDemo["default"].check_call(method, path, params)) {
        var data = _incusVastaanotaUtilsDemo["default"].handle_call(this, method, path, params);
        return data;
      }

      var ajaxParameters = {
        url: url,
        method: method,
        data: params,
        beforeSend: function beforeSend(xhr) {
          xhr.withCredentials = true;
          xhr.setRequestHeader("Authorization", "Bearer " + token);
        },
        dataType: "json",
        crossDomain: true
      };
      // Override some parameters for PUT requests.
      if (method === "PUT" || method === "DELETE") {
        ajaxParameters.processData = false;

        console.log([method, path, params, contentType, token]);

        if (!contentType) {
          contentType = '"application/json; charset=UTF-8"';
          /*
          Ember.Logger.log("No Content-Type defined for PUT request: " + path);
          return new Ember.RSVP.Promise(function(resolve, reject) {
              reject('No Content-Type defined for PUT request');
          });
          */
        }
        ajaxParameters.contentType = contentType;
      }

      _ember["default"].Logger.log(ajaxParameters.url);

      this.get("spinner").show();

      return new _ember["default"].RSVP.Promise(function (resolve, reject) {
        return _ember["default"].$.ajax(_ember["default"].$.extend(ajaxParameters, {
          success: function success(data) {
            _this3.get("spinner").hide();
            resolve(data);
          },
          error: function error(data, status, errorThrown) {
            _this3.get("spinner").hide();
            _ember["default"].Logger.log(data);
            _ember["default"].Logger.log("failure");
            _ember["default"].Logger.log(["failure", status, errorThrown]);
            _ember["default"].run(null, reject, data);
          }
        }));
      });
    },

    _wrapSetMetadata: function _wrapSetMetadata(metadata) {
      return '<Request version="1.0">\r\n<Content>' + metadata + "</Content></Request>\r\n";
    },

    /* LASKUMAPPI API
     *
     */

    loginByEmail: function loginByEmail(username, password) {
      var uid = username;
      var pass = password;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([username, t], password);

      _ember["default"].Logger.log("making call");

      //note:parameter 'pass' must be included but it can be anything

      return this._apiCall("GET", "/authuser", {
        uid: uid,
        pass: "",
        t: t,
        d: d
      });
    },

    getSession: function getSession(traid, tkey) {
      var t = this._formattedTime();
      var d = this._digestStringWithValue([traid, t, tkey]);

      _ember["default"].Logger.log("making call");

      //note:parameter 'pass' must be included but it can be anything

      return this._apiCall("GET", "/session", {
        TraID: traid,
        t: t,
        d: d
      });
    },
    deleteSession: function deleteSession(traid, tkey) {
      var t = this._formattedTime();
      var d = this._digestStringWithValue([traid, t, tkey]);
      return this._apiCall("DELETE", "/session?" + _ember["default"].$.param({
        TraID: traid,
        t: t,
        d: d
      }));
    },

    login: function login(username, password, app) {
      var uid = username;
      var pass = password;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([username, t], password);

      _ember["default"].Logger.log('making call');
      var that = this;
      //note:parameter 'pass' must be included but it can be anything
      return new _ember["default"].RSVP.Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open("PUT", _incusVastaanotaConfigEnvironment["default"].authserverApiRoot + "/login");
        xhr.onload = function () {
          var userInfo = undefined;
          if (xhr.responseXML) {
            userInfo = that._transformToObject(xhr.responseXML);
          } else {
            userInfo = { callStatus: { success: "OK" }, data: JSON.parse(xhr.responseText) };
          }
          if (xhr.readyState == 4 && xhr.status == "200") {
            resolve(userInfo);
          } else if (xhr.readyState == 4 && xhr.status == "500") {
            reject("500");
          } else {
            reject(userInfo);
          }
        };

        xhr.send(JSON.stringify({ username: username, password: password, app: app }));
      });

      /*     return this._apiCall("GET", "/login", {
            uid, app:app, t, d
          }); */
    },

    pitstopTraffic: function pitstopTraffic(uniqueCompanyId, transferId, transferKey) {
      var params = {};

      var t = this._formattedTime();
      var digestArray = [uniqueCompanyId, t, transferKey];

      params.uniqueCompanyID = uniqueCompanyId;
      params.TraID = transferId;
      params.t = t;
      params.d = this._digestStringWithValue(digestArray);

      if (_incusVastaanotaUtilsDemo["default"].check_env()) {
        return _incusVastaanotaUtilsDemo["default"].handle_call(this, "GET", _incusVastaanotaConfigEnvironment["default"].pitstopApiRoot + "/Customer", params);
      }

      return this._terminalApiCall("GET", _incusVastaanotaConfigEnvironment["default"].pitstopApiRoot + "/Customer", params);
    },

    pitstopUpdateHeld: function pitstopUpdateHeld(uniqueMessageID, action, role, transferId, transferKey) {
      var params = {
        uniqueMessageID: uniqueMessageID,
        action: action,
        role: role
        //transferId:transferId,
      };

      var digestArray = [uniqueMessageID, role, transferKey];

      params.uniqueMessageID = uniqueMessageID;
      params.TraID = transferId;
      params.d = this._digestStringWithValue(digestArray);

      return this._terminalApiCall("POST", _incusVastaanotaConfigEnvironment["default"].pitstopApiRoot + "/UpdateHeld", params);
    },

    pitstopHold: function pitstopHold(uniqueMessageID, buyerEmail, transferId, transferKey) {
      var t = this._formattedTime();

      var params = {
        TraID: transferId,
        uniqueMessageID: uniqueMessageID,
        buyerEmail: buyerEmail,
        t: t
      };

      var digestArray = [uniqueMessageID, buyerEmail, t, transferKey];

      /*
      params.uniqueMessageID = uniqueMessageID;
      params.buyerEmail = buyerEmail;
      params.t = t;
       */
      params.d = this._digestStringWithValue(digestArray);

      return this._terminalApiCall("POST", _incusVastaanotaConfigEnvironment["default"].pitstopApiRoot + "/Hold", params);
    },

    pitstopContract: function pitstopContract(uniqueCompanyID, signerEmail, signerName, transferId, transferKey) {
      var t = this._formattedTime();

      var params = {
        TraID: transferId,
        uniqueCompanyID: uniqueCompanyID,
        signerEmail: signerEmail,
        signerName: signerName,
        t: t
      };

      var digestArray = [uniqueCompanyID, signerEmail, signerName, t, transferKey];

      /*
      params.uniqueMessageID = uniqueMessageID;
      params.buyerEmail = buyerEmail;
      params.t = t;
       */
      params.d = this._digestStringWithValue(digestArray);

      return this._terminalApiCall("POST", _incusVastaanotaConfigEnvironment["default"].pitstopApiRoot + "/Contract", params);
    },

    pitstopDelete: function pitstopDelete(uniqueMessageID, transferId, transferKey) {
      var t = this._formattedTime();

      var params = {
        TraID: transferId,
        uniqueMessageID: uniqueMessageID,
        t: t
      };

      var digestArray = [uniqueMessageID, t, transferKey];

      params.d = this._digestStringWithValue(digestArray);

      return this._terminalApiCall("POST", _incusVastaanotaConfigEnvironment["default"].pitstopApiRoot + "/Delete", params);
    },

    removeDupcheck: function removeDupcheck(item, uniqueCompanyId, businessId, transferId, transferKey) {
      var t = this._formattedTime();

      var documentId = businessId + "_" + item.get("documentID");

      var params = {
        TraID: transferId,
        cid: uniqueCompanyId,
        did: documentId,
        t: t
      };

      var digestArray = [uniqueCompanyId, documentId, transferId, t, transferKey];

      params.d = this._digestStringWithValue(digestArray);

      return this._apiCall("DELETE", "/dupcheck?" + _ember["default"].$.param(params), null);
    },

    sentTraffic: function sentTraffic(uniqueCompanyId, transferId, transferKey, limit) {
      var params = {};

      if (!limit) {
        limit = 1000;
      }

      var t = this._formattedTime();
      var digestArray = [uniqueCompanyId, transferId, limit, t, transferKey];

      params.id = uniqueCompanyId;
      params.TraID = transferId;
      params.limit = limit;
      params.t = t;
      params.d = this._digestStringWithValue(digestArray);

      return this._apiCall("GET", "/sent", params);
    },

    ReceivedTraffic: function ReceivedTraffic(uniqueCompanyId, transferId, transferKey, limit) {
      var params = {};

      if (!limit) {
        limit = 1000;
      }
      //limit = limit.toString();

      var t = this._formattedTime();
      var digestArray = [uniqueCompanyId, transferId, limit, t, transferKey];

      params.id = uniqueCompanyId;
      params.TraID = transferId;
      params.limit = limit;
      params.t = t;
      params.d = this._digestStringWithValue(digestArray);

      return this._apiCall("GET", "/received", params);
    },

    searchReceivedArchive: function searchReceivedArchive(uniqueCompanyId, transferId, transferKey, from, to, due, did, freeText, senderName) {
      var params = {};
      var digestArray = [];
      var id = uniqueCompanyId;
      params.id = id;
      digestArray.push(id);

      if (!from) {
        from = "";
      }
      params.from = from;
      digestArray.push(from);
      if (!to) {
        to = "";
      }
      params.to = to;
      digestArray.push(to);

      if (did) {
        params.did = did;
        digestArray.push(did);
      }
      if (senderName) {
        params.sender = senderName;
        digestArray.push(senderName);
      }

      if (freeText) {
        params.freetext = freeText;
        digestArray.push(freeText);
      }

      params.TraID = transferId;
      digestArray.push(transferId);

      params.t = this._formattedTime();
      digestArray.push(params.t);

      if (due) {
        params.due = due;
        digestArray.push(due);
      }

      digestArray.push(transferKey);
      var d = this._digestStringWithValue(digestArray);
      params.d = d;
      return this._apiCall("GET", "/searchreceivedarchive", params);
    },

    searchSentArchive: function searchSentArchive(uniqueCompanyId, transferId, transferKey, from, to, due, did, freeText, receiverName) {
      var params = {};
      var digestArray = [];
      var id = uniqueCompanyId;
      params.id = id;
      digestArray.push(id);

      if (!from) {
        from = "";
      }
      params.from = from;
      digestArray.push(from);
      if (!to) {
        to = "";
      }
      params.to = to;
      digestArray.push(to);

      if (did) {
        params.did = did;
        digestArray.push(did);
      }
      if (receiverName) {
        params.receiver = receiverName;
        digestArray.push(receiverName);
      }
      if (freeText) {
        params.freetext = freeText;
        digestArray.push(freeText);
      }
      params.TraID = transferId;
      digestArray.push(transferId);

      params.t = this._formattedTime();
      digestArray.push(params.t);

      if (due) {
        params.due = due;
        digestArray.push(due);
      }

      digestArray.push(transferKey);

      var d = this._digestStringWithValue(digestArray);
      params.d = d;
      return this._apiCall("GET", "/searchsentarchive", params);
    },

    /* Generates a url matching downloadbyrole
     */
    generatePitStopDownloadURL: function generatePitStopDownloadURL(uniqueCompanyId, transferId, transferKey, uniqueMessageID, role, type) {
      var t = this._formattedTime();

      var digestArray = [uniqueMessageID, role, transferKey];

      var d = this._digestStringWithValue(digestArray);

      var param = {
        //t:t,
        TraID: transferId,
        uniqueMessageID: uniqueMessageID,
        role: role,
        type: type,
        d: d
      };

      var extension = "";

      if (type == "data") {
        extension = ".xml";
      } else if (type == "image") {
        extension = ".pdf";
      }

      _ember["default"].Logger.log(["generatePitStopDownloadURL", param]);

      //this._downloadByRoleParams(uniqueCompanyId, transferId, transferKey, uniqueMessageID, role);
      var url = _incusVastaanotaConfigEnvironment["default"].pitstopApiRoot + "/View/" + uniqueMessageID + extension + "?" + _ember["default"].$.param(param);
      //Ember.Logger.log("url is: " + url);

      if (_incusVastaanotaUtilsDemo["default"].check_env()) {
        url = _incusVastaanotaUtilsDemo["default"].get_dl_link(this, uniqueCompanyId, transferId, transferKey, uniqueMessageID, role, type, extension);
      }

      return url;
    },

    /* Generates a url matching downloadbyrole
     */
    generateDownloadURL: function generateDownloadURL(uniqueCompanyId, transferId, transferKey, storageId, type) {
      var param = this._downloadByRoleParams(uniqueCompanyId, transferId, transferKey, storageId, type);
      var url = _incusVastaanotaConfigEnvironment["default"].apiRoot + "/downloadbyrole?" + _ember["default"].$.param(param);
      //Ember.Logger.log("url is: " + url);
      return url;
    },

    /* Downloads a file from a storage by role of the file. Known roles are:
     * image, invoice. Note that there is an external interface to apix
     * securestorage for additional download methods.
     */
    downloadByRole: function downloadByRole(uniqueCompanyId, transferId, transferKey, storageId, type) {
      var param = this._downloadByRoleParams(uniqueCompanyId, transferId, transferKey, storageId, type);
      var url = this.generateDownloadURL(uniqueCompanyId, transferId, transferKey, storageId, type);
      return this._binaryApiCall("GET", url, null, null, "application/pdf");
    },

    _downloadByRoleParams: function _downloadByRoleParams(uniqueCompanyId, transferId, transferKey, storageId, type) {
      var id = uniqueCompanyId;
      var TraID = transferId;
      var sid = storageId;

      var t = this._formattedTime();

      var d = this._digestStringWithValue([id, sid, type, TraID, t, transferKey]);
      return {
        sid: sid,
        type: type,
        id: id,
        TraID: TraID,
        t: t,
        d: d
      };
    },

    /* TERMINAL API
     *
     *
     */

    // generate the url for web link
    downloadURL: function downloadURL(storageId, storageKey, markreceived, name, returnname, type, disposition) {
      var SID = storageId;

      var t = this._formattedTime();

      var digestParams = [];
      if (markreceived) {
        digestParams.push(markreceived);
      }
      if (name) {
        digestParams.push(name);
      }
      if (returnname) {
        digestParams.push(returnname);
      }
      if (type) {
        digestParams.push(type);
      }
      if (disposition) {
        digestParams.push(disposition);
      }
      digestParams.push(SID, t, storageKey);

      //let d = this._digestStringWithValue([SID, t, storageKey]);
      var d = this._digestStringWithValue(digestParams);
      var url = _incusVastaanotaConfigEnvironment["default"].terminalApiRoot + "/download?" + _ember["default"].$.param({
        SID: SID,
        markreceived: markreceived,
        name: name,
        returnname: returnname,
        type: type,
        disposition: disposition,
        t: t,
        d: d
      });
      //Ember.Logger.log("url: " + url);
      var contentType = "";
      return url;
      //	return this._terminalApiCall("GET", "/download?", null, contentType );
    },

    // XXX Can't really use this with ajax
    downloadAttachment: function downloadAttachment(storageId, storageKey, fileName, attachmentFileType) {
      //this.downloadURL(storageId, storageKey, fileName)
      var SID = storageId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([SID, t, storageKey]);
      var url = _incusVastaanotaConfigEnvironment["default"].terminalApiRoot + "/download?" + _ember["default"].$.param({
        SID: SID,
        t: t,
        d: d
      });
      //Ember.Logger.log("url: " + url);
      var contentType = "text/xml";

      var xml = '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>' + '<Request version="1.0"><Content><Group><Value type="FileName">' + _incusVastaanotaUtilsEmail["default"].escapeXML(fileName) + "</Value></Group></Content></Request>";

      return this._binaryApiCall("PUT", url, xml, contentType, attachmentFileType);
    },

    _binaryApiCall: function _binaryApiCall(method, url, requestData, contentType, attachmentFileType, getAsBlob) {
      this._eventLogEntry(url, method);
      _ember["default"].Logger.log("Binary api call start for", url);
      this.get("spinner").show();
      var me = this;
      return new _ember["default"].RSVP.Promise(function (resolve, reject) {
        var oReq = new XMLHttpRequest();
        oReq.open(method, url, true);
        if (contentType) {
          oReq.setRequestHeader("Content-Type", contentType);
        }
        oReq.responseType = "arraybuffer";
        oReq.onreadystatechange = function () {
          if (this.readyState === this.DONE) {
            me.get("spinner").hide();
            if (this.status === 200) {
              // Test if Blob and createObjectURL are supported.
              // We reject it for IE for now because we're hitting bad behavior
              var URL = window.URL || window.webkitURL;
              if (getAsBlob && window.File) {
                // The Content-Type of all the responses are application/octet-stream,
                // so we have to use the FileType we already know.
                resolve(new window.Blob([this.response], { type: attachmentFileType }));
              } else {
                _ember["default"].Logger.log("Not using createObjectURL");
                // IE 8 & 9
                var binary = "";
                var bytes = new Uint8Array(this.response);
                var len = bytes.byteLength;
                for (var i = 0; i < len; i++) {
                  binary += String.fromCharCode(bytes[i]);
                }
                var value = "data:" + attachmentFileType + ";base64," + window.btoa(binary);
                resolve(value);
              }
            } else {
              reject(new Error("_binaryApiCall: `" + url + "` failed with status: [" + this.status + "]"));
            }
          }
        };

        oReq.send(requestData);
      });
    },

    /*
    <Content>
    <Group>
    <Value type="StorageID">147be668-c5e9-4c36-98a8-ece79cf702a6</Value>
    <Value type="FileName">invoice.xml</Value>
    <Value type="FileSize">5331</Value>
    <Value type="FileMimeType">text/xml</Value>
    <Value type="FileStatus">UNLOCKED</Value>
    </Group>
    <Group>
    <Value type="StorageID">147be668-c5e9-4c36-98a8-ece79cf702a6</Value>
    <Value type="FileName">invoice.pdf</Value>
    <Value type="FileSize">106366</Value>
    <Value type="FileMimeType">application/pdf</Value>
    <Value type="FileStatus">UNLOCKED</Value>
    </Group>
    */
    /* upload
     *
     * Upload file to storage
     */
    upload: function upload(transferId, storageId, mimeType, fileName, transferKey, fileData, contentType, role) {
      var SID = storageId;
      var TraID = transferId;
      var mime = mimeType;
      var name = fileName;
      var t = this._formattedTime();
      var pArray = [];
      var params = {};
      var d = undefined;
      if (role) {
        pArray = [SID, name, mime, role, TraID, t, transferKey];
        d = this._digestStringWithValue(pArray);
        params = {
          TraID: TraID,
          t: t,
          SID: SID,
          mime: mime,
          role: role,
          name: name,
          d: d
        };
      } else {
        pArray = [SID, name, mime, TraID, t, transferKey];
        d = this._digestStringWithValue(pArray);
        params = {
          TraID: TraID,
          t: t,
          SID: SID,
          mime: mime,
          name: name,
          d: d
        };
      }
      var url = _incusVastaanotaConfigEnvironment["default"].terminalApiRoot + "/attachment?" + _ember["default"].$.param(params);
      _ember["default"].Logger.log("url: " + url);
      return this._terminalApiCall("PUT", url, fileData, contentType);
    },

    uploadBySid: function uploadBySid(storageId, mimeType, fileName, storageKey, fileData, contentType) {
      var SID = storageId;
      var mime = mimeType;
      var name = fileName;
      var t = this._formattedTime();
      var pArray = [];
      var params = {};
      var d = undefined;

      pArray = [name, mime, SID, t, storageKey];
      d = this._digestStringWithValue(pArray);
      params = {
        t: t,
        SID: SID,
        mime: mime,
        name: name,
        d: d
      };

      var url = _incusVastaanotaConfigEnvironment["default"].terminalApiRoot + "/uploadbysid?" + _ember["default"].$.param(params);
      _ember["default"].Logger.log("url: " + url);
      return this._terminalApiCall("PUT", url, fileData, contentType);
    },

    /* delete
     *
     * Delete file from storage
     */
    deleteAttachment: function deleteAttachment(transferId, storageId, fileName, transferKey) {
      var SID = storageId;
      var TraID = transferId;
      var name = fileName;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([SID, name, TraID, t, transferKey]);
      var url = _incusVastaanotaConfigEnvironment["default"].terminalApiRoot + "/attachment?" + _ember["default"].$.param({
        TraID: TraID,
        SID: SID,
        name: name,
        t: t,
        d: d
      });
      _ember["default"].Logger.log(url);
      return this._terminalApiCall("DELETE", url);
    },

    /* Get list of files in Storage
     */

    listAttachment: function listAttachment(storageId, storageKey) {
      var SID = storageId;
      var t = this._formattedTime();
      //let d = this._digestStringWithValue([ sid, t ], storageKey);
      var d = this._digestStringWithValue([SID, t, storageKey]);
      return this._terminalApiCall("GET", _incusVastaanotaConfigEnvironment["default"].terminalApiRoot + "/listattachment", {
        SID: SID,
        t: t,
        d: d
      });
    },

    /* createStorage
     *
     * Return storageId and storageKey
     */

    createStorage: function createStorage(transferId, transferKey, name) {
      var TraID = transferId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([name, TraID, t, transferKey]);
      return this._terminalApiCall("GET", _incusVastaanotaConfigEnvironment["default"].terminalApiRoot + "/create", {
        TraID: TraID,
        t: t,
        name: name,
        d: d
      });
    },

    createReceiving: function createReceiving(transferId, transferKey, name) {
      var TraID = transferId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([name, TraID, t, transferKey]);
      return this._terminalApiCall("GET", _incusVastaanotaConfigEnvironment["default"].terminalApiRoot + "/createreceiving", {
        TraID: TraID,
        t: t,
        name: name,
        d: d
      });
    },

    listStorage: function listStorage(transferId, transferKey) {
      var TraID = transferId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([TraID, t, transferKey]);
      return this._terminalApiCall("GET", _incusVastaanotaConfigEnvironment["default"].terminalApiRoot + "/liststorage", {
        TraID: TraID,
        t: t,
        d: d
      });
    },

    deleteStorage: function deleteStorage(transferId, storageId, transferKey) {
      var url = this.deleteStorageURL(transferId, storageId, transferKey);
      return this._terminalApiCall("DELETE", url);
    },

    deleteStorageURL: function deleteStorageURL(transferId, storageId, transferKey) {
      var SID = storageId;
      var TraID = transferId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([SID, TraID, t, transferKey]);
      var url = _incusVastaanotaConfigEnvironment["default"].terminalApiRoot + "/storage?" + _ember["default"].$.param({
        TraID: TraID,
        SID: SID,
        t: t,
        d: d
      });
      _ember["default"].Logger.log(url);
      return url;
    },

    /* getMetadata
     *
     * Get metadata for storageId
     */

    getMetadata: function getMetadata(transferId, storageId, transferKey) {
      var SID = storageId;
      var TraID = transferId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([SID, TraID, t, transferKey]);
      return this._terminalApiCall("GET", _incusVastaanotaConfigEnvironment["default"].terminalApiRoot + "/metadata", {
        TraID: TraID,
        SID: SID,
        t: t,
        d: d
      });
    },

    /* receiverMetadata
     *
     * curl -k "https://test-terminal.apix.fi/receivermetadata?t=20150921072739&d=SHA-256:5d0f3cbc1d59d53c63a7c374377e27184ef5dbf193119d7d4fa20164cebf48bd&SID=95513897-26d6-4298-b352-f39da05b5ecd"
     */
    receiverMetadata: function receiverMetadata(storageId, storageKey) {
      var SID = storageId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([SID, t, storageKey]);
      return this._terminalApiCall("GET", _incusVastaanotaConfigEnvironment["default"].terminalApiRoot + "/receivermetadata", {
        SID: SID,
        t: t,
        d: d
      });
    },

    /* setMetadata
     *
     * Set for storageId. If no Filename tag in metadata xml, will set for
     * storage itself, otherwise for the file.
     */

    setMetadata: function setMetadata(transferId, storageId, transferKey, metadata, contentType) {
      var SID = storageId;
      var TraID = transferId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([SID, TraID, t, transferKey]);
      metadata = this._wrapSetMetadata(metadata);
      var url = _incusVastaanotaConfigEnvironment["default"].terminalApiRoot + "/metadata?" + _ember["default"].$.param({
        TraID: TraID,
        SID: SID,
        t: t,
        d: d
      });
      _ember["default"].Logger.log("url: " + url);
      return this._terminalApiCall("PUT", url, metadata, contentType);
    },

    /* addMetadata
     *
     */

    addMetadata: function addMetadata(storageId, storageKey, metadata, contentType) {
      var SID = storageId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([SID, t, storageKey]);
      _ember["default"].Logger.log(metadata);
      metadata = this._wrapSetMetadata(metadata);
      var url = _incusVastaanotaConfigEnvironment["default"].terminalApiRoot + "/addmetadata?" + _ember["default"].$.param({
        SID: SID,
        t: t,
        d: d
      });
      _ember["default"].Logger.log("url: " + url);
      return this._terminalApiCall("PUT", url, metadata, contentType);
    },

    /* storageInfo
       * Returns info for specific storageid
    <Response><Status>OK</Status><StatusCode>2700</StatusCode><Content><Group><Value type="StorageID">147be668-c5e9-4c36-98a8-ece79cf702a6</Value><Value type="StorageName">5f82599b-fbc8-48ed-b842-aae4ce41cc67</Value><Value type="StorageSize">111697</Value><Value type="StorageKey">997013006094</Value><Value type="ExpiryDate">20150612122147</Value><Value type="CreationDate">20150611122147</Value><Value type="StorageStatus">RECEIVED</Value><Value type="BucketName">apix.receiving.test</Value><Value type="StorageQuota">681239295</Value></Group></Content></Response>
        */
    storageInfo: function storageInfo(storageId, storageKey) {
      var SID = storageId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([SID, t, storageKey]);
      return this._terminalApiCall("GET", _incusVastaanotaConfigEnvironment["default"].terminalApiRoot + "/storageinfo", {
        SID: SID,
        t: t,
        d: d
      });
    },

    /*
     * request deliverymethod
     */

    askDeliveryMethod: function askDeliveryMethod(transferId, transferKey, xml) {
      var uid = transferId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([uid, t, transferKey]);
      var url = _incusVastaanotaConfigEnvironment["default"].testApiRoot + "/method?" + _ember["default"].$.param({ uid: uid, t: t, d: d });
      return this._terminalApiCall("PUT", url, xml, "text/xml");
    },

    /*
     * ask for list of addresses
     */

    askAddresses: function askAddresses(transferId, transferKey, vat) {
      var uid = transferId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([uid, t, transferKey]);
      var url = _incusVastaanotaConfigEnvironment["default"].testApiRoot + "/addressquery?" + _ember["default"].$.param({ uid: uid, t: t, d: d });
      var header = '<?xml version="1.0" encoding="UTF-8"?>\n';
      var xml = header + "<Request>" + "<Content>" + "<Group>" + '<Value type="ReceiverYtunnus">' + _incusVastaanotaUtilsEmail["default"].escapeXML(vat) + "</Value>" + "</Group>" + "</Content>" + "</Request>";
      return this._terminalApiCall("PUT", url, xml, "text/xml");
    },

    /* Send new invoice
     * Allows sending of a ZIP-file containing one to several invoices (inhouse format)
     * and their corresponding PDF-images (as single files) and optionally also
     * attachments (as single zip-files) to the invoices
     *
     */

    sendInvoiceZip: function sendInvoiceZip(transferId, transferKey, zipFile, soft, ver) {
      var TraID = transferId;
      var t = this._formattedTime();
      if (!soft) {
        soft = "Standard";
      }
      if (!ver) {
        ver = "1.0";
      }
      var d = this._digestStringWithValue([soft, ver, TraID, t, transferKey]);
      var url = _incusVastaanotaConfigEnvironment["default"].testApiRoot + "/invoices?" + _ember["default"].$.param({ soft: soft, ver: ver, TraID: TraID, t: t, d: d });
      return this._terminalApiCall("PUT", url, zipFile, "application/octet-stream");
    },

    /* Allows listing of all invoices in the service.
     * The service will return list of invoices and the "fetch status" of all
     * available invoices.
     *
     */

    listInvoiceZips2: function listInvoiceZips2(transferId, transferKey) {
      var TraID = transferId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([TraID, t, transferKey]);

      return this._terminalApiCall("GET", _incusVastaanotaConfigEnvironment["default"].terminalApiRoot + "/listforlaskumappi", {
        TraID: TraID,
        t: t,
        d: d
      });
    },

    //https://test-api.apix.fi/storagesmtp?subject=testmail&from=servicedesk@apix.fi&to=antti.marjala@apix.fi&t=20150715125014&d=SHA-256:79b312e96ba9f5e20e3bfe2aaf967500c6306b8660086db2f752be07f3587b28&sid=5b41a4fd-ca38-4007-b521-4b80cf06db13
    sendEmail: function sendEmail(subject, from, to, storageId, attach, storageKey, xml) {
      var t = this._formattedTime();
      var SID = storageId;
      var key = storageKey;
      var d = this._digestStringWithValue([subject, from, to, SID, attach, t, key]);
      var url = _incusVastaanotaConfigEnvironment["default"].terminalApiRoot + "/storagesmtp?" + _ember["default"].$.param({
        subject: subject,
        from: from,
        to: to,
        SID: SID,
        attach: attach,
        t: t,
        d: d
      });
      _ember["default"].Logger.log("url: " + url);
      return this._terminalApiCall("PUT", url, xml, "text/xml");

      /*
      digest lasketaan:$digest_src=$subject."+".$from."+".$to."+".$sid."+".$timestamp."+".$key;
      tossa siis sid on storageid ja key on storagekey
      PUT request ja xml:ään sisältö. Multipart message jossa eri groupeissa noi eri osat. joka groupissa pitää olla content ja saa olla MimeType. MimeType defaulttaa tuohon esimerkissä olevaan text/html
       lähetys xml siis esim:
       <?xml version="1.0" encoding="utf-8" standalone="yes"?>
      <Request>
      <Content>
      <Group>
      <Value type="MimeType">text/html; charset=UTF-8</Value>
      <Value type="Content">here be the content&lt;br/&gt;with some html tagas&lt;p&gt;foo&lt;/p&gt;</Value>
      </Group>
      </Content>
      </Request>
       toi ei just nyt tee muuta kuin lähettää ton meilin ja virheilmotkin voi olla kuraa, teen lisää heti kun ehdin. Valitettavasti nyt näyttää kyllä siltä, että en ehdi kaikkea sovittua tehdä ennen elokuuta (1 päivä jäljellä ja tää on ihan vaiheessa).
      */
    },

    // TODO the real backend for these does not exist yet.
    getEmailRecipients: function getEmailRecipients(transferId, storageId, transferKey) {
      var sid = storageId;
      var TraID = transferId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([sid, TraID, t, transferKey]);
      return [
        //      'test'
      ];
      //return this._terminalApiCall("GET", "/emailrecipient", {sid, TraID, t, d});
    },
    addEmailRecipient: function addEmailRecipient(transferId, storageId, transferKey, emailRecipient) {
      var sid = storageId;
      var TraID = transferId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([sid, TraID, t, emailRecipient, transferKey]);
      //return this._terminalApiCall("PUT", url, {sid, TraID, emailRecipient, t, d});
    },
    removeEmailRecipient: function removeEmailRecipient(transferId, storageId, transferKey, emailRecipient) {
      var sid = storageId;
      var TraID = transferId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([sid, TraID, t, emailRecipient, transferKey]);
      var url = _incusVastaanotaConfigEnvironment["default"].testApiRoot + "/emailrecipient?" + _ember["default"].$.param({
        TraID: TraID,
        sid: sid,
        emailRecipient: emailRecipient,
        t: t,
        d: d
      });
      //return this._terminalApiCall("DELETE", url, {sid, TraID, emailRecipient, t, d});
    },

    updateNotificationEmails: function updateNotificationEmails(transferId, transferKey, arrival, dueDate) {
      var TraID = transferId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([TraID, t, arrival, dueDate, transferKey]);
      var url = _incusVastaanotaConfigEnvironment["default"].testApiRoot + "/notifications?" + _ember["default"].$.param({
        TraID: TraID,
        arrival: arrival,
        dueDate: dueDate,
        t: t,
        d: d
      });
      return new _ember["default"].RSVP.Promise();
    },

    setContract: function setContract(adminId, adminKey, uniqueCompanyId, requestData) {
      var TraID = adminId;
      var cid = uniqueCompanyId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([cid, TraID, t, adminKey]);
      var url = _incusVastaanotaConfigEnvironment["default"].adminApiRoot + "/addcontract?" + _ember["default"].$.param({
        cid: cid,
        TraID: TraID,
        t: t,
        d: d
      });
      var contentType = "text/xml";
      _ember["default"].Logger.log("url: " + url);
      _ember["default"].Logger.log(requestData);
      return this._terminalApiCall("PUT", url, requestData, contentType);
    },

    deleteContract: function deleteContract(adminId, adminKey, uniqueCompanyId, contract_type, requestData) {
      var TraID = adminId;
      var cid = uniqueCompanyId;
      var t = this._formattedTime();
      // var dparams=[uniqueID,ctype,tid,timestamp,key].join("+");
      var d = this._digestStringWithValue([cid, contract_type, TraID, t, adminKey]);
      var url = _incusVastaanotaConfigEnvironment["default"].adminApiRoot + "/deletecontract?" + _ember["default"].$.param({
        cid: cid,
        TraID: TraID,
        contract_type: contract_type,
        t: t,
        d: d
      });
      var contentType = "text/xml";
      _ember["default"].Logger.log("url: " + url);
      return this._terminalApiCall("DELETE", url, null, contentType);
    },

    listContract: function listContract(adminId, adminKey, uniqueCompanyId) {
      var TraID = adminId;
      var cid = uniqueCompanyId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([cid, TraID, t, adminKey]);
      var url = _incusVastaanotaConfigEnvironment["default"].adminApiRoot + "/listcontract?" + _ember["default"].$.param({
        TraID: TraID,
        cid: cid,
        t: t,
        d: d
      });
      _ember["default"].Logger.log("url: " + url);
      return this._terminalApiCall("GET", url);
    },

    GetSaldo: function GetSaldo(uniqueCompanyId) {
      var url = _incusVastaanotaConfigEnvironment["default"].testApiRoot + "/saldo?" + _ember["default"].$.param({
        id: uniqueCompanyId
      });

      _ember["default"].Logger.log("url: " + url);
      return this._terminalApiCall("GET", url);
    },

    html2pdf: function html2pdf(tid, tkey, requestData, getAsBlob) {
      var TraID = tid;
      //let cid = uniqueCompanyId;
      var contentType = "text/html";

      var t = this._formattedTime();
      var d = this._digestStringWithValue([tid, t, tkey]);
      var url = _incusVastaanotaConfigEnvironment["default"].testApiRoot + "/html2pdf?" + _ember["default"].$.param({
        TraID: TraID,
        t: t,
        d: d
      });
      _ember["default"].Logger.log("url: " + url);
      return this._binaryApiCall("PUT", url, requestData, contentType, "application/pdf", getAsBlob);
      //return this._terminalApiCall("PUT", url, requestData, contentType);
    },

    _xAuthServerGetToken: function _xAuthServerGetToken(userData) {
      var token = userData.authtoken;
      var expires = userData.authtoken_expires;

      if (token && expires > Date.now()) {
        return token;
      } else {
        return this._AuthServerGetToken(userData);
      }
    },

    _AuthServerGetToken: function _AuthServerGetToken(userData) {
      var tid = userData.TransferID;
      var tkey = userData.TransferKey;

      var t = this._formattedTime();
      var d = this._digestStringWithValue([tid, t, tkey]);

      var params = _ember["default"].$.param({
        TraID: tid,
        Timestamp: t,
        Digest: d
      });

      var url = _incusVastaanotaConfigEnvironment["default"].authserverApiRoot + "/token/auth";

      return this._JSONApiCall("GET", url, params).then(function (data) {
        userData.authtoken = data.token;
        userData.authtoken_expires = Date.now() + data.expires * 1000 - 5000;

        return data.token;
      });
    },

    TrafficLightsGetBusinessIds: function TrafficLightsGetBusinessIds(userData, bids, is_retry) {
      var _this4 = this;

      var token = this._xAuthServerGetToken(userData);

      var url = _incusVastaanotaConfigEnvironment["default"].trafficLightsApiRoot + "/trafficlights/check";

      var request = {
        bids: bids
        //FetchIfMissing: true,
      };

      var params = _ember["default"].$.param({ business_ids: bids.join(",") });

      return _ember["default"].RSVP.resolve(token).then(function (token) {
        return _this4._JSONApiCall("PUT", url, JSON.stringify(request), "application/json; charset=UTF-8", token);
      })["catch"](function (err) {
        if (!is_retry) {
          userData.authtoken = null;
          return _this4.TrafficLightsGetBusinessIds(userData, bids, true);
        } else {
          console.error(["TrafficLightsGetBusinessIds err", err]);
        }
      });
    },

    TrafficLightsGetPaymentHistoryRecords: function TrafficLightsGetPaymentHistoryRecords(userData, bid) {
      var _this5 = this;

      //return Ember.RSVP.resolve(JSON.parse(test_records));
      var token = this._xAuthServerGetToken(userData);

      var url = _incusVastaanotaConfigEnvironment["default"].trafficLightsApiRoot + "/trafficlights/records";

      var request = {
        businessId: bid
      };

      return _ember["default"].RSVP.resolve(token).then(function (token) {
        return _this5.TokenValidation(userData).then(function (ok) {
          if (ok) {
            return token;
          } else {
            return _this5._AuthServerGetToken(userData);
          }
        });
      }).then(function (token) {
        return _this5._JSONApiCall("PUT", url, JSON.stringify(request), "application/json; charset=UTF-8", token);
      })["catch"](function (err) {
        return _ember["default"].RSVP.reject(err);
      });
    },

    TokenValidation: function TokenValidation(userData) {
      var _this6 = this;

      var token = this._xAuthServerGetToken(userData);

      var url = _incusVastaanotaConfigEnvironment["default"].authserverApiRoot + "/token/check";
      //const params = Ember.$.param({});

      return _ember["default"].RSVP.resolve(token).then(function (token) {
        return _this6._JSONApiCall("PUT", url, null, "application/json; charset=UTF-8", token);
      }).then(function (data) {
        return data.valid;
      })["catch"](function (err) {
        console.log([err, err.status, err.responseText]);
        return false;
      });
    },

    UpdateCustomToken: function UpdateCustomToken(userData, context, xtoken, expires) {
      var _this7 = this;

      var token = this._xAuthServerGetToken(userData);

      var url = _incusVastaanotaConfigEnvironment["default"].authserverApiRoot + "/token/custom/update";

      var request = {
        context: context,
        token: xtoken,
        expires: expires
      };

      return _ember["default"].RSVP.resolve(token).then(function (token) {
        return _this7.TokenValidation(userData).then(function (ok) {
          if (ok) {
            return token;
          } else {
            return _this7._AuthServerGetToken(userData);
          }
        });
      }).then(function (token) {
        return _this7._JSONApiCall("PUT", url, JSON.stringify(request), "application/json; charset=UTF-8", token);
      })["catch"](function (err) {
        return _ember["default"].RSVP.reject(err);
      });
    },

    AuthorizeToken: function AuthorizeToken(userData, context, authorization_code) {
      var _this8 = this;

      var token = this._xAuthServerGetToken(userData);

      var url = _incusVastaanotaConfigEnvironment["default"].authserverApiRoot + "/token/custom/oauth_authorize";

      var request = {
        context: context,
        authorization_code: authorization_code
      };

      return _ember["default"].RSVP.resolve(token).then(function (token) {
        return _this8.TokenValidation(userData).then(function (ok) {
          if (ok) {
            return token;
          } else {
            return _this8._AuthServerGetToken(userData);
          }
        });
      }).then(function (token) {
        return _this8._JSONApiCall("PUT", url, JSON.stringify(request), "application/json; charset=UTF-8", token);
      })["catch"](function (err) {
        return _ember["default"].RSVP.reject(err);
      });
    },

    OAuthInit: function OAuthInit(userData, context) {
      var _this9 = this;

      var token = this._xAuthServerGetToken(userData);

      var url = _incusVastaanotaConfigEnvironment["default"].authserverApiRoot + "/token/custom/oauth/init/" + context;

      return _ember["default"].RSVP.resolve(token).then(function (token) {
        return _this9.TokenValidation(userData).then(function (ok) {
          if (ok) {
            return token;
          } else {
            return _this9._AuthServerGetToken(userData);
          }
        });
      }).then(function (token) {
        return _this9._JSONApiCall("PUT", url, null, "application/json; charset=UTF-8", token);
      })["catch"](function (err) {
        return _ember["default"].RSVP.reject(err);
      });
    },

    GetCustomToken: function GetCustomToken(userData, context) {
      var _this10 = this;

      var token = this._xAuthServerGetToken(userData);

      var url = _incusVastaanotaConfigEnvironment["default"].authserverApiRoot + "/token/custom/get";

      var request = {
        context: context
      };

      return _ember["default"].RSVP.resolve(token).then(function (token) {
        return _this10.TokenValidation(userData).then(function (ok) {
          if (ok) {
            return token;
          } else {
            return _this10._AuthServerGetToken(userData);
          }
        });
      }).then(function (token) {
        return _this10._JSONApiCall("PUT", url, JSON.stringify(request), "application/json; charset=UTF-8", token);
      })["catch"](function (err) {
        return _ember["default"].RSVP.reject(err);
      });
    },

    CheckCustomToken: function CheckCustomToken(userData, context) {
      var _this11 = this;

      var token = this._xAuthServerGetToken(userData);

      var url = _incusVastaanotaConfigEnvironment["default"].authserverApiRoot + "/token/custom/check";

      var request = {
        context: context
      };

      return _ember["default"].RSVP.resolve(token).then(function (token) {
        return _this11.TokenValidation(userData).then(function (ok) {
          if (ok) {
            return token;
          } else {
            return _this11._AuthServerGetToken(userData);
          }
        });
      }).then(function (token) {
        return _this11._JSONApiCall("PUT", url, JSON.stringify(request), "application/json; charset=UTF-8", token);
      })["catch"](function (err) {
        return _ember["default"].RSVP.reject(err);
      });
    },

    getYTJ: function getYTJ(transferId, transferKey, bid) {
      var TraID = transferId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([TraID, bid, t, transferKey]);

      return this._terminalApiCall("GET", _incusVastaanotaConfigEnvironment["default"].authserverApiRoot + "/ytj", {
        TraID: TraID,
        t: t,
        d: d,
        bid: bid
      });
    },

    ValidateFinvoice: function ValidateFinvoice(userData, finvoice) {
      var _this12 = this;

      var token = this._xAuthServerGetToken(userData);
      var url = _incusVastaanotaConfigEnvironment["default"].authserverApiRoot + "/validation/finvoice";

      return _ember["default"].RSVP.resolve(token).then(function (token) {
        return _this12.TokenValidation(userData).then(function (ok) {
          if (ok) {
            return token;
          } else {
            return _this12._AuthServerGetToken(userData);
          }
        });
      }).then(function (token) {
        return _this12._JSONApiCall("PUT", url, finvoice, "text/xml", token);
      })["catch"](function (err) {
        return _ember["default"].RSVP.reject(err);
      });
    },

    Finvoice2Taltio: function Finvoice2Taltio(userData, finvoice) {
      var _this13 = this;

      var token = this._xAuthServerGetToken(userData);
      var url = _incusVastaanotaConfigEnvironment["default"].authserverApiRoot + "/taltio/finvoice";

      return _ember["default"].RSVP.resolve(token).then(function (token) {
        return _this13.TokenValidation(userData).then(function (ok) {
          if (ok) {
            return token;
          } else {
            return _this13._AuthServerGetToken(userData);
          }
        });
      }).then(function (token) {
        return _this13._JSONApiCall("PUT", url, finvoice, "text/xml", token);
      })["catch"](function (err) {
        return _ember["default"].RSVP.reject(err);
      });
    },

    checkReceivereInvoiceAddress: function checkReceivereInvoiceAddress(Ytunnus, name, transferId, transferKey, address) {
      var uid = transferId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([uid, t, transferKey]);
      var url = _incusVastaanotaConfigEnvironment["default"].testApiRoot + "/method?" + _ember["default"].$.param({ uid: uid, t: t, d: d });
      var header = '<?xml version="1.0" encoding="UTF-8"?>\n';
      var xml = header + "<Request>" + "<Content>" + "<Group>" + '<Value type="SenderName">' + name + "</Value>" + '<Value type="SenderYtunnus">' + Ytunnus + "</Value>" + '<Value type="ReceivereInvoiceAddress">' + address + "</Value>" + '<Value type="ReceiverName">N/A</Value>' + "</Group>" + "</Content>" + "</Request>";
      return this._terminalApiCall("PUT", url, xml, "text/xml");
    },

    queryAddress: function queryAddress(Ytunnus, transferId, transferKey) {
      var uid = transferId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([uid, t, transferKey]);
      var url = _incusVastaanotaConfigEnvironment["default"].testApiRoot + "/addressquery?" + _ember["default"].$.param({ uid: uid, t: t, d: d });
      var header = '<?xml version="1.0" encoding="UTF-8"?>\n';
      var xml = header + "<Request>" + "<Content>" + "<Group>" + '<Value type="ReceiverYtunnus">' + Ytunnus + "</Value>" + "</Group>" + "</Content>" + "</Request>";
      return this._terminalApiCall("PUT", url, xml, "text/xml");
    },

    fetchInvoicingCustomerData: function fetchInvoicingCustomerData(userData) {
      var _this14 = this;

      var url = _incusVastaanotaConfigEnvironment["default"].invoicingSystem + "/customer/get";
      var token = this._xAuthServerGetToken(userData);
      var params = null;

      return _ember["default"].RSVP.resolve(token).then(function (token) {
        return _this14._JSONApiCall("GET", url, params, "application/json; charset=UTF-8", token);
      })["catch"](function (err) {
        return _ember["default"].RSVP.reject(err);
      });
    },

    updateInvoicingCustomerData: function updateInvoicingCustomerData(userData, data) {
      var _this15 = this;

      var url = _incusVastaanotaConfigEnvironment["default"].invoicingSystem + "/customer/update";
      var token = this._xAuthServerGetToken(userData);
      var params = data;

      return _ember["default"].RSVP.resolve(token).then(function (token) {
        return _this15._JSONApiCall("PUT", url, JSON.stringify(params), "application/json; charset=UTF-8", token);
      })["catch"](function (err) {
        return _ember["default"].RSVP.reject(err);
      });
    },

    updateInvoicingAnyParty: function updateInvoicingAnyParty(userData, data, id) {
      var _this16 = this;

      var url = _incusVastaanotaConfigEnvironment["default"].invoicingSystem + "/anyparty";
      var token = this._xAuthServerGetToken(userData);
      var params = data;

      return _ember["default"].RSVP.resolve(token).then(function (token) {
        return _this16._JSONApiCall("GET", url, params, "application/json; charset=UTF-8", token);
      })["catch"](function (err) {
        return _ember["default"].RSVP.reject(err);
      });
    },

    sendInvoicingCustomerLogo: function sendInvoicingCustomerLogo(userData, data) {
      var _this17 = this;

      var url = _incusVastaanotaConfigEnvironment["default"].invoicingSystem + "/logo";
      var token = this._xAuthServerGetToken(userData);

      return _ember["default"].RSVP.resolve(token).then(function (token) {
        return _this17._JSONApiCall("PUT", url, JSON.stringify({ Logo: data }), "application/json; charset=UTF-8", token);
      })["catch"](function (err) {
        return _ember["default"].RSVP.reject(err);
      });
    },

    fetchInvoicingCustomerLogo: function fetchInvoicingCustomerLogo(userData) {
      var _this18 = this;

      var url = _incusVastaanotaConfigEnvironment["default"].invoicingSystem + "/logo";
      var token = this._xAuthServerGetToken(userData);
      return _ember["default"].RSVP.resolve(token).then(function (token) {
        return _this18._JSONApiCall("GET", url, null, "application/json; charset=UTF-8", token);
      })["catch"](function (err) {
        return _ember["default"].RSVP.reject(err);
      });
    },

    deleteInvoicingCustomerLogo: function deleteInvoicingCustomerLogo(userData) {
      var _this19 = this;

      var url = _incusVastaanotaConfigEnvironment["default"].invoicingSystem + "/logo";
      var token = this._xAuthServerGetToken(userData);

      return _ember["default"].RSVP.resolve(token).then(function (token) {
        return _this19._JSONApiCall("DELETE", url, null, "application/json; charset=UTF-8", token);
      })["catch"](function (err) {
        return _ember["default"].RSVP.reject(err);
      });
    },
    //Registration
    registrationAddCustomer: function registrationAddCustomer(adminId, adminKey, requestData) {
      var TraID = adminId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([TraID, t, adminKey]);
      var url = _incusVastaanotaConfigEnvironment["default"].adminApiRoot + "/adduser?" + _ember["default"].$.param({
        TraID: TraID,
        t: t,
        d: d
      });
      var contentType = "text/xml";
      _ember["default"].Logger.log("url: " + url);
      return this._registrationApiCall("PUT", url, requestData, contentType);
    },

    registrationSetContract: function registrationSetContract(adminId, adminKey, uniqueCompanyId, requestData) {
      var TraID = adminId;
      var cid = uniqueCompanyId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([cid, TraID, t, adminKey]);
      var url = _incusVastaanotaConfigEnvironment["default"].adminApiRoot + "/addcontract?" + _ember["default"].$.param({
        cid: cid,
        TraID: TraID,
        t: t,
        d: d
      });
      var contentType = "text/xml";
      _ember["default"].Logger.log("url: " + url);
      return this._registrationApiCall("PUT", url, requestData, contentType);
    },

    registrationListContract: function registrationListContract(adminId, adminKey, uniqueCompanyId) {
      var TraID = adminId;
      var cid = uniqueCompanyId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([cid, TraID, t, adminKey]);
      var url = _incusVastaanotaConfigEnvironment["default"].adminApiRoot + "/listcontract?" + _ember["default"].$.param({
        TraID: TraID,
        cid: cid,
        t: t,
        d: d
      });
      _ember["default"].Logger.log("url: " + url);
      return this._registrationApiCall("GET", url);
    },

    _registrationApiCall: function _registrationApiCall(method, path, params, contentType) {
      var _this20 = this;

      _ember["default"].Logger.log(method + " " + path);
      //Ember.Logger.log(params);
      var url = path;

      var ajaxParameters = {
        url: url,
        method: method,
        data: params,
        dataType: "xml"
      };
      // Override some parameters for PUT requests.
      if (method === "PUT" || method === "DELETE") {
        ajaxParameters.processData = false;
        ajaxParameters.url = path;
        if (!contentType) {
          contentType = "";
        }
        ajaxParameters.contentType = contentType;
      }
      _ember["default"].Logger.log(ajaxParameters.url);
      this.get("spinner").show();
      return new _ember["default"].RSVP.Promise(function (resolve, reject) {
        return _ember["default"].$.ajax(_ember["default"].$.extend(ajaxParameters, {
          success: function success(data) {
            _this20.get("spinner").hide();
            if (data) {
              data = _this20._transformToObject(data);
              if (data.callStatus.success === "OK") {
                _ember["default"].Logger.log("OK: " + method + " " + path);
                resolve(data);
              } else {
                _ember["default"].Logger.log("ERROR: " + method + " " + path);
                resolve(data);
              }
            } else {
              _ember["default"].Logger.log("Request success, but no data received.");
              resolve(null);
            }
          },
          error: function error(data) {
            _this20.get("spinner").hide();
            _ember["default"].Logger.log(data);
            _ember["default"].Logger.log("failure");
            _ember["default"].run(null, reject, data);
          }
        }));
      });
    },

    changePassword: function changePassword(userData, oldPass, newPass, callback) {
      var _this21 = this;

      var uid = userData.Email;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([userData.Email, t], oldPass);

      //note:parameter 'pass' must be included but it can be anything

      return this._apiCall("GET", "/authuser", {
        uid: uid,
        pass: "",
        t: t,
        d: d
      }).then(function (response) {
        if (response.callStatus.success === "OK" && response.callStatus.statusCode === "2U00") {
          var TraID = userData.TransferID;
          var tkey = userData.TransferKey;
          var cid = userData.UniqueCompanyID;
          var _t = _this21._formattedTime();
          var _d = _this21._digestStringWithValue([cid, TraID, _t, tkey]);
          var url = _incusVastaanotaConfigEnvironment["default"].adminApiRoot + "/modifyuser?" + _ember["default"].$.param({
            cid: cid,
            TraID: TraID,
            t: _t,
            d: _d
          });
          var contentType = "text/xml";
          var requestData = "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"yes\"?>\n        <Request version=\"1.0\">\n             <FreeText language=\"ISO639-1 Languagecode\"></FreeText>\n             <Content>\n                <Group>\n                <Value type=\"Password\">" + newPass + "</Value>\n                <Value type=\"Email\">" + userData.Email + "</Value>\n                <Value type=\"y-tunnus\">" + userData.Ytunnus + "</Value>\n                <Value type=\"Name\">" + userData.Name + "</Value>\n                <Value type=\"Language\">" + userData.LanguageCoded + "</Value>\n                </Group>\n             </Content>\n        </Request>";
          _ember["default"].Logger.log("url: " + url);
          _ember["default"].Logger.log(requestData);
          return _this21._terminalApiCall("PUT", url, requestData, contentType);
        } else {
          callback("wrongPass");
        }
      }).then(function (result) {
        if (result.callStatus.success === "OK" && result.callStatus.statusCode === "2A00") {
          console.log("result");
          console.log(result);
          callback("success");
        } else {
          callback("fail");
        }
      })["catch"](function (error) {
        console.log(error);
      });
    },
    changeUserData: function changeUserData(userData, requestData) {
      var TraID = userData.TransferID;
      var tkey = userData.TransferKey;
      var cid = userData.UniqueCompanyID;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([cid, TraID, t, tkey]);
      var url = _incusVastaanotaConfigEnvironment["default"].adminApiRoot + "/modifyuser?" + _ember["default"].$.param({
        cid: cid,
        TraID: TraID,
        t: t,
        d: d
      });
      var contentType = "text/xml";
      _ember["default"].Logger.log("url: " + url);
      _ember["default"].Logger.log(requestData);
      return this._terminalApiCall("PUT", url, requestData, contentType);
    },
    addSubuser: function addSubuser(userData, subuser, is_retry) {
      var _this22 = this;

      var token = this._xAuthServerGetToken(userData);

      var url = _incusVastaanotaConfigEnvironment["default"].authserverApiRoot + '/subuser/add';

      return _ember["default"].RSVP.resolve(token).then(function (token) {
        return _this22._JSONApiCall("PUT", url, JSON.stringify(subuser), "application/json; charset=UTF-8", token);
      })["catch"](function (err) {
        if (!is_retry) {
          userData.authtoken = null;
          return _this22.addSubuser(userData, subuser, true);
        } else {
          console.error(["Subuser add err", err]);
        }
      });
    },

    listSubusers: function listSubusers(userData, is_retry) {
      var _this23 = this;

      var token = this._xAuthServerGetToken(userData);

      var url = _incusVastaanotaConfigEnvironment["default"].authserverApiRoot + '/subuser/list';

      return _ember["default"].RSVP.resolve(token).then(function (token) {
        return _this23._JSONApiCall("GET", url, null, "application/json; charset=UTF-8", token);
      })["catch"](function (err) {
        if (!is_retry) {
          userData.authtoken = null;
          return _this23.listSubusers(userData, true);
        } else {
          console.error(["Subuser list err", err]);
        }
      });
    },

    deleteSubuser: function deleteSubuser(userData, id, is_retry) {
      var _this24 = this;

      var token = this._xAuthServerGetToken(userData);

      var url = _incusVastaanotaConfigEnvironment["default"].authserverApiRoot + '/subuser/delete/' + id;

      return _ember["default"].RSVP.resolve(token).then(function (token) {
        return _this24._JSONApiCall("DELETE", url, null, "application/json; charset=UTF-8", token);
      })["catch"](function (err) {
        if (!is_retry) {
          userData.authtoken = null;
          return _this24.listSubusers(userData, true);
        } else {
          console.error(["deleteSubuser err", err]);
        }
      });
    },

    updateSubuser: function updateSubuser(userData, user, is_retry) {
      var _this25 = this;

      var token = this._xAuthServerGetToken(userData);

      var url = _incusVastaanotaConfigEnvironment["default"].authserverApiRoot + '/subuser/update';

      return _ember["default"].RSVP.resolve(token).then(function (token) {
        return _this25._JSONApiCall("PUT", url, JSON.stringify(user), "application/json; charset=UTF-8", token);
      })["catch"](function (err) {
        if (!is_retry) {
          userData.authtoken = null;
          return _this25.listSubusers(userData, true);
        } else {
          console.error(["updateSubuser err", err]);
        }
      });
    },
    checkSubuser: function checkSubuser(userData, id, is_retry) {
      var _this26 = this;

      var token = this._xAuthServerGetToken(userData);

      var url = _incusVastaanotaConfigEnvironment["default"].authserverApiRoot + '/subuser/check/' + id;

      return _ember["default"].RSVP.resolve(token).then(function (token) {
        console.log(token);
        return _this26._JSONApiCall("GET", url, null, "application/json; charset=UTF-8", token);
      })["catch"](function (err) {
        if (!is_retry) {
          userData.authtoken = null;
          return _this26.checkSubuser(userData, id, true);
        } else {
          console.error(["Check subuser err", err]);
        }
      });
    },
    listUsers: function listUsers(adminId, adminKey, uniqueCompanyId) {
      var TraID = adminId;
      var cid = uniqueCompanyId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([TraID, t, adminKey]);
      var url = _incusVastaanotaConfigEnvironment["default"].adminApiRoot + "/listuser?" + _ember["default"].$.param({
        TraID: TraID,
        t: t,
        d: d
      });
      _ember["default"].Logger.log("url: " + url);
      return this._terminalApiCall("GET", url);
    },
    getUser: function getUser(adminId, adminKey, uniqueCompanyId) {
      var TraID = adminId;
      var cid = uniqueCompanyId;
      var t = this._formattedTime();
      var d = this._digestStringWithValue([cid, TraID, t, adminKey]);
      var url = _incusVastaanotaConfigEnvironment["default"].adminApiRoot + "/user?" + _ember["default"].$.param({
        cid: cid,
        TraID: TraID,
        t: t,
        d: d
      });
      _ember["default"].Logger.log("url: " + url);
      return this._terminalApiCall("GET", url);
    },
    checkPasswordRenewal: function checkPasswordRenewal(id) {
      var _this27 = this;

      this.get("spinner").show();
      return new _ember["default"].RSVP.Promise(function (resolve, reject) {
        return _ember["default"].$.ajax({
          url: _incusVastaanotaConfigEnvironment["default"].authserverApiRoot + '/resetpassword?' + _ember["default"].$.param({ id: id }),
          method: "GET",
          success: function success(data) {
            _this27.get("spinner").hide();
            resolve(data);
          },
          error: function error(data) {
            console.log("err", data);
            _this27.get("spinner").hide();
            reject(data);
          }
        });
      });
    },
    requestPasswordRenewal: function requestPasswordRenewal(username, domain, language) {
      var _this28 = this;

      this.get("spinner").show();
      return new _ember["default"].RSVP.Promise(function (resolve, reject) {
        return _ember["default"].$.ajax({
          url: _incusVastaanotaConfigEnvironment["default"].authserverApiRoot + '/resetpassword',
          method: "POST",
          data: JSON.stringify({ username: username, domain: domain, language: language }),
          dataType: "json",
          contentType: '"application/json; charset=UTF-8"',
          success: function success(data) {
            _this28.get("spinner").hide();
            resolve(data);
          },
          error: function error(data) {
            _this28.get("spinner").hide();
            _ember["default"].run(null, reject, data);
          }
        });
      });
    },
    changePasswordRenewal: function changePasswordRenewal(password, id) {
      var _this29 = this;

      this.get("spinner").show();
      return new _ember["default"].RSVP.Promise(function (resolve, reject) {
        return _ember["default"].$.ajax({
          url: _incusVastaanotaConfigEnvironment["default"].authserverApiRoot + '/resetpassword',
          method: "PUT",
          data: JSON.stringify({ password: password, id: id }),
          dataType: "json",
          contentType: '"application/json; charset=UTF-8"',
          success: function success(data) {
            _this29.get("spinner").hide();
            resolve(data);
          },
          error: function error(data) {
            _this29.get("spinner").hide();
            _ember["default"].run(null, reject, data);
          }
        });
      });
    }
  });
});